<template>
  <div class="data-required">
    <v-form ref="form">
      <v-row
        class="py-5"
        style="margin-bottom: -50px"
      >
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.name"
              label="Nama"
              outlined
              type="text"
              dense
              @change="handleChangeName($event)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-if="error === true"
              v-model="headdepartment.username"
              label="Username"
              :rules="[rules.alreadyTaken]"
              dense
              value
              outlined
              :hint="hint"
              persistent-hint
              error
              @change="checkUsername($event)"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="headdepartment.username"
              label="Username"
              dense
              value
              outlined
              :hint="hint"
              persistent-hint
              @change="checkUsername($event)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="headdepartment.password"
              label="Password"
              :append-icon="show ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
              :type="show ? 'text' : 'password'"
              outlined
              dense
              @click:append="show = !show"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div>
            <v-autocomplete
              v-model="headdepartment.province_uuid"
              :items="provinces"
              label="Provinsi"
              item-text="name"
              item-value="uuid"
              dense
              outlined
              return-object
              :disabled="!provinces.length"
              hint="Domisili Dinas"
              persistent-hint
              @change="changeListRegencies()"
            >
            </v-autocomplete>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <v-autocomplete
              v-model="headdepartment.regencies_uuid"
              :items="regenciesByProvince"
              label="Kabupaten/Kota"
              return-object
              item-text="name"
              persistent-hint
              hint="Domisili Dinas"
              item-value="uuid"
              dense
              outlined
              clearable
              :messages="isNullRegencies"
              :disabled="!regenciesByProvince.length"
            >
            </v-autocomplete>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import randomString from '@/utils/random-string/randomString'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  name: 'DataRequired',
  props: {
    editUuid: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      error: false,
      hint: '',
      dialog: '',
      show: false,
      isHide: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      provinces: [],
      regencies: [],
      regenciesByProvince: [],
      isNullRegencies: '',
      InitialKadis: {
        name: '',
        username: '',
        password: '',
        province_uuid: '',
        regencies_uuid: '',
      },
      headdepartment: { ...this.InitialKadis },
      formValid: false,
    }
  },
  watch: {
    editUuid() {
      if (this.editUuid) this.get()
    },
    headdepartment: {
      handler() {
        const valid = []
        const requiredField = ['name', 'username', 'password']
        Object.entries(this.headdepartment).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)

        this.$emit('change-valid')

        this.changeListRegencies(this.headdepartment.province_uuid)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.editUuid) this.get()
    this.listProvince()
    this.listRegency()
  },
  methods: {
    resetData() {
      this.$refs.form.reset()
    },

    async get() {
      await this.$services.ApiServices.get('headdepartment', this.editUuid).then(
        ({ data }) => {
          this.headdepartment = {
            uuid: data.data.uuid,
            name: data.data.name,
            username: data.data.username,
            password: '',
            province_uuid: {
              name: data.data.province?.name,
              uuid: data.data.province?.uuid,
            },
            regencies_uuid: {
              name: data.data.regencies?.name,
              uuid: data.data.regencies?.uuid,
            },
          }
        },
        err => console.error(err),
      )
    },

    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },

    async listRegency() {
      await this.$services.ApiServices.list('regency', { per_page: 'all' }).then(
        ({ data }) => {
          this.regencies = data.data
        },
        err => console.error(err),
      )
    },

    changeListRegencies() {
      this.regenciesByProvince = this.regencies.filter(
        item => item.province_uuid === this.headdepartment.province_uuid?.uuid,
      )
      // if (this.regenciesByProvince.length < 1) {
      //   this.isNullRegencies = 'Provinsi yang anda pilih tidak memiliki kabupaten'
      // }
    },

    async checkUsername(username) {
      await this.$services.AuthServices.checkUsername(username).then(
        ({ data }) => {
          this.error = false
          this.hint = data.result.message
        },
        err => {
          this.error = true
          this.hint = err.message
          console.error(err)
        },
      )
    },

    handleChangeName() {
      if (this.headdepartment.name.length > 3) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          const randomUsername = randomString(this.headdepartment.name)
          await this.checkUsername(randomUsername)
          this.headdepartment.username = randomUsername
        }, 500)
      }
    },
  },
}
</script>

<style>
</style>
